import { css } from "@emotion/react"
import Markdown from 'markdown-to-jsx';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Logo from "../images/logo.inline.svg"

import moment from "../utils/moment"
import markdownToText from "../utils/markdown-to-text"
import truncateText from "../utils/truncate"
import Layout from "../components/layout"
import Tag from "../components/tag"
import SEO from "../components/seo"
import { theme } from "../constants/theme"

const Author = ({ author, createdAt }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <div
      css={css`
        margin-right: 10px;
        height: 45px;
      `}
    >
      {author && author.photo ? (
        <GatsbyImage
          css={css`border-radius: 50%;`}
          image={getImage(author.photo)} alt={author.name}
        />
      ) : (
        <Logo
          css={css`
            height: 45px;
            width: 45px;
            border-radius: 50%;
          `}
        />
      )}
    </div>
    <div>
      <div
        css={css`
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: ${theme.colors.primary700};
        `}
      >
        {author ? author.name || author.username : "thoth"}
      </div>
      <div
        css={css`
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: ${theme.colors.primary400};
        `}
      >
        {moment(createdAt).format("LL")}
      </div>
    </div>
  </div>
)

export default function Blog({ data }) {
  const article = data.strapiArticle
  return (
    <Layout base="/blog" title="托特 | 部落格">
      <SEO
        title={`${article.title}`}
        description={truncateText(markdownToText(article.content))}
        image={article.cover.publicURL}
      />
      <h1
        css={css`
          font-weight: 400;
          font-size: 40px;
          margin-top: 0;
          margin-bottom: 20px;
          @media ${theme.devices.max.mobile} {
            font-size: 28px;
          }
        `}
      >
        {article.title}
      </h1>
      <div
        css={css`
          margin-bottom: 40px;
        `}
      >
        <Author author={article.author} createdAt={article.created_at} />
      </div>
      <div
        css={css`
          margin-bottom: 40px;
        `}
      >
        <GatsbyImage image={getImage(article.cover)} />
      </div>
      <div
        css={css`
          color: ${theme.colors.primary700};
          & h1, h2, h3 {
            margin-top: 45px;
            font-weight: 600;
          }

          & h4, h5, h6 {
            margin-top: 20px;
            font-weight: 600;
          }

          & b, strong {
            font-weight: 600;
          }

          & a {
            color: ${theme.colors.primary500};
            text-decoration: underline;
          }

          & p {
            margin: 25px 0;
          }
        `}
      >
        <Markdown>{article.content}</Markdown>
      </div>
      <div
        css={css`
          display: flex;
          margin-top: 50px;
          margin-bottom: 20px;
        `}
      >
        {article.tags.map(tag => (
          <Tag
            key={tag.name}
            css={css`
              margin-right: 8px;
            `}
          >
            {tag.name}
          </Tag>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($articleId: Int) {
    strapiArticle(strapiId: { eq: $articleId }, status: { eq: "Published" }) {
      strapiId
      title
      content
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 680)
        }
      }
      created_at
      tags {
        name
      }
      author {
        name
        username
        photo {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 45, height: 45)
          }
        }
      }
    }
  }
`
